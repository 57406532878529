<template>
  <v-scale-transition>
    <kits-panel :kits-items="subscriberGroup">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        XOX LEAD Program
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
    }
  },
  computed: {
    subscriberGroup: function () {
      const menu = [
        {
          icon: 'mdi-note-text-outline',
          path: '/training_materials',
          title: 'Training Materials',
          desc: 'Download slide',
        },
        {
          icon: 'mdi-certificate',
          path: '/lead',
          title: 'Certificates',
          desc: 'Download certificates',
        },
        // {
        //   icon: 'mdi-clipboard-text',
        //   path: '/credit_hours',
        //   title: 'Credit Hours',
        //   desc: 'View score',
        // },
        // {
        //   icon: 'mdi-link-plus',
        //   path: '/rsvp_lead',
        //   title: 'RSVP Lead Classes',
        //   desc: 'RSVP',
        // },
      ]

      return menu
    },
  },
}
</script>
